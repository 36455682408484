import styled from 'styled-components'

export const MediaContainer = styled.div`
  aspect-ratio: 896/790;

  > * {
    width: 100%;
    height: auto;
  }
`
