import React, { memo } from 'react'
import { LANGUAGE_CODES } from '@typeform/ginger/dist/lib/i18n'
import BaseMediaTextModule, {
  TMediaTextModuleProps,
} from 'components/modules/media-text-module'
import RenderHtml from 'components/atoms/render-html'
import { TActionBarLinkProps } from 'components/molecules/action-bar'

import useHomePageMedia from '../use-homepage-media'

import { MediaContainer } from './media-text-module.styles'

const ASSETS_PATH = {
  [LANGUAGE_CODES.english]: 'homepage-refresh/media-text-module',
  [LANGUAGE_CODES.spanish]: 'homepage-refresh/media-text-module/es',
}

type THomepageMediaTextModuleProps = Omit<
  TMediaTextModuleProps,
  'palette' | 'media' | 'cornersStyle'
> & {
  'title': string
  'mediaSlug': string
  'cta': TActionBarLinkProps
  'data-testid': string
}

const MediaTextModule = ({
  mediaSlug,
  eyebrow,
  title,
  body,
  cta,
  layout,
  'data-testid': dataTestId,
}: THomepageMediaTextModuleProps) => {
  const { media } = useHomePageMedia({
    assetsPath: ASSETS_PATH,
    slug: mediaSlug,
    aboveTheFold: false,
    altText: title,
  })

  return (
    <BaseMediaTextModule
      eyebrow={eyebrow}
      title={title}
      body={<RenderHtml as='span' html={body} />}
      layout={layout}
      actionBar={{
        primaryLink: cta,
      }}
      media={<MediaContainer>{media}</MediaContainer>}
      data-testid={dataTestId}
    />
  )
}

MediaTextModule.displayName = 'HomepageMediaTextModule'

export default memo(MediaTextModule)
